$isProd: true;
.planCardListWrapper2 {
  grid-template-columns: repeat(2, minmax(250px, 1fr));
}

.planCardListWrapper3 {
  grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.planCardListWrapper4 {
  grid-template-columns: repeat(4, minmax(250px, 1fr));
}

.planCardListWrapper5 {
  grid-template-columns: repeat(5, minmax(250px, 1fr));
}

.planCardListWrapper6 {
  grid-template-columns: repeat(6, minmax(250px, 1fr));
}
